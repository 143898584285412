@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}

@layer components {
  .question-inputs {
    @apply border border-gray-400 p-2 mb-4 rounded-md bg-transparent text-slate-400;
  }
}

body {
  margin: 0;
  padding: 20px;
  box-sizing: border-box;
  font-family: "Poppins";
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-image: url('/src/assets/display.png'); */
  background-size: cover;
  overflow-x: hidden;
}

* {
  color: #030303;
  margin: 0;
  font-weight: 500;
}

main {
  max-width: 1200px;
  margin: 40px auto;
}

/* .root-layout{
    background-color: #ffffff6d;
    min-height: 100vh;
    /* min-width: 750px; */
/* padding: 50px;    */
/* }  */

.content {
  flex: 1;
}

/* RootLayout styles */
header nav {
  display: flex;
  /* justify-content: flex-end; */
  /* margin: 0 auto; */
  max-width: 1200px;
  /* gap: 20px; */
}

header nav h1 {
  font-size: 55px;
  font-style: italic;
  font-weight: 900px;
  margin-right: auto;
  border-bottom: 2px solid black;
}

header nav a {
  text-decoration: none;
  padding: 6px;
  border-radius: 6px;
}

header nav a.active {
  background: rgb(5, 110, 145);
}

/* App footer */
/* .footer {
    padding-top: 8rem;
    text-align: center; 
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-content {
    display: flex;
    align-items: center;
  }

.footer-content img{
    width: 170px;
    height: 100px;
}

.footer-content span {
    font-weight: bold;
    font-size: 25px;
    height: 46px;
  } */

/* Home Page Styles */
.inputs {
  display: flex;
  flex-direction: column;
  max-width: 600px;
}

.quiz-rules__items {
  margin-bottom: 50px;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
}

.button {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

/* Quiz styles */
.quiz-category {
  text-align: center;
  font-size: 35px;
}

/* Question styles */
.current-question {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: flex-start; */
  /* height: 50vh; */
}

.current-question__number {
  text-align: center;
  margin-bottom: 20px;
  font-weight: 600;
}

/* .current-question__choice{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    border: 2px solid #42a5f5;
    padding: 7px;
    margin-top: 20px;
    font-size: 20px;
    border-radius: 15px;
    cursor: pointer;
} */

.current-question__next-button {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.selected-button {
  background-color: #42a5f5;
  color: white;
}

/* MEDIA QUERIES */

/* Home page */
@media screen and (max-width: 992px) {
  .root-layout {
    padding: 10px;
  }
  .quiz-container {
    min-height: 700px;
    padding: 10px;
    margin-top: 20px;
  }
}

@media screen and (max-width: 784px) {
  .root-layout header nav {
    margin-left: 26px;
  }
  .root-layout header nav h1 {
    font-size: 54px;
  }
  .quiz-container {
    min-height: 600px;
    margin: 0;
    padding: 25px;
  }
}

@media screen and (max-width: 730px) {
  .root-layout header nav {
    margin-left: 55px;
  }
  .quiz-container {
    margin-left: 35px;
  }
  .quiz-rules__items h5 {
    font-size: 20px;
  }
}

@media screen and (max-width: 665px) {
  .root-layout header nav {
    margin-left: 190px;
  }
  .quiz-container {
    margin-left: 110px;
    padding-top: 0;
    height: 650px;
  }
  .quiz-rules h5 {
    font-size: 20px;
  }
  .quiz-rules__items h5 {
    font-size: 18px;
  }
  .inputs {
    max-width: 480px;
  }
  /* footer{
        margin-left: 60px;
       
    } */
}

@media screen and (max-width: 525px) {
  .root-layout header nav {
    margin-left: 226px;
  }
  .quiz-container {
    margin-left: 145px;
  }
  .quiz-rules__items h5 {
    font-size: 18px;
  }
  .inputs {
    max-width: 480px;
  }
  /* footer{
        margin-left: 120px;
    } */
}

@media screen and (max-width: 460px) {
  .root-layout header nav {
    margin-left: 270px;
  }
  .root-layout header nav h1 {
    font-size: 46px;
  }
  .quiz-container {
    margin-left: 190px;
  }
  .quiz-rules__items h5 {
    font-size: 17px;
  }
  .inputs {
    max-width: 420px;
  }
  /* footer{
        margin-left: 183px;
    } */
}

@media screen and (max-width: 376px) {
  .root-layout header nav {
    margin-left: 300px;
  }
  .quiz-container {
    margin-left: 225px;
  }
  .inputs {
    max-width: 360px;
  }
  /* footer{
        margin-left: 225px;
    } */
}

/* Quiz page */
@media screen and (max-width: 992px) {
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 700px;
  }
}
@media screen and (max-width: 730px) {
  .quiz-category {
    font-size: 29px;
  }
  .current-question {
    width: 500px;
  }
  .current-question__number {
    font-size: 26px;
  }
  .choices_container {
    max-width: 450px;
  }
}

@media screen and (max-width: 622px) {
  .current-question {
    max-width: 400px;
  }
  .current-question__next-button {
    width: 180px;
    margin-left: 7.3rem;
  }
}

@media screen and (max-width: 512px) {
  .quiz-category {
    font-size: 28px;
    margin-left: 8.9rem;
  }
  .current-question {
    width: 410px;
    padding-left: 8.7rem;
  }
  .current-question__choice {
    font-size: 16px;
    width: 340px;
  }
  .current-question__next-button {
    width: 160px;
    margin-left: 6.4rem;
  }
}

@media screen and (max-width: 429px) {
  .quiz-category {
    font-size: 24px;
    margin-left: 9.8rem;
  }
  .current-question {
    width: 340px;
    padding-left: 10.6rem;
  }
  .current-question__number {
    font-size: 22px;
  }
  .current_question__item {
    font-size: 22px;
  }
  .current-question__choice {
    font-size: 18px;
    width: 280px;
  }

  .current-question__next-button {
    width: 80px;
    margin-left: 5.8rem;
  }
  /* .footer span{
        font-size: 24px;
    }
    .footer img {
        width: 150px;
        height: 100px;
        object-fit: contain;
    } */
}

@media screen and (max-width: 330px) {
  .quiz-category {
    margin-left: 10rem;
  }
  .current-question {
    width: 240px;
  }
}

.loader {
  width: 28px;
  height: 28px;
  border: 5px solid #fff;
  border-bottom-color: #22c55e;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
